// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../../../src/templates/category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */),
  "component---src-templates-index-index-tsx": () => import("./../../../src/templates/index/index.tsx" /* webpackChunkName: "component---src-templates-index-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-subcategory-index-tsx": () => import("./../../../src/templates/subcategory/index.tsx" /* webpackChunkName: "component---src-templates-subcategory-index-tsx" */),
  "component---src-templates-tags-index-tsx": () => import("./../../../src/templates/tags/index.tsx" /* webpackChunkName: "component---src-templates-tags-index-tsx" */)
}

